import { transformAsCurrency } from '~/utils/functions';
import { calculaValorIpi } from '../auxiliares/calculaValorIpi';

export function procedureSubtotais(itens: any[]) {
  let Total_Tab_Liq = 0;
  let Total_Tab = 0;
  let Ipi = 0;
  let Total_IPI = 0;
  let Credito_ICMS = 0;
  let Total_Nota_Fiscal = 0;
  let Total_ICMS_ST = 0;

  // eslint-disable-next-line no-restricted-syntax
  for (const item of itens) {
    if (item.qtd_entrada > 0) {
      // Total Tabela Liquido  ==> Total dos itens sem IPI ....
      Total_Tab_Liq += transformAsCurrency(item?.val_tabela_liq);
      Total_Tab += transformAsCurrency(item?.val_total);
      // Total IPI
      Ipi = calculaValorIpi(
        transformAsCurrency(item?.val_total),
        transformAsCurrency(item?.val_tabela_liq),
        transformAsCurrency(item?.val_ipi),
      );
      Total_IPI += item?.val_ipi;
      // ICMS ST
      Total_ICMS_ST += transformAsCurrency(item.val_icms_st);
      // Crédito ICMS
      Credito_ICMS += transformAsCurrency(item?.val_icms);
    }
    Total_Nota_Fiscal = Total_Tab_Liq + Total_IPI + Total_ICMS_ST;
  }
  return {
    Total_Tab,
    Total_IPI,
    Credito_ICMS,
    Total_Nota_Fiscal,
    Total_ICMS_ST,
  };
}
