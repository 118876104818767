/* eslint-disable no-lonely-if */
import { FornecedorProps } from '../../protocols/FornecedorProps';
import { ItensProps } from '~/components/BuscaNfe/protocols/ItensProps';
import getTributacoes from '../../services/getTributacoes';
import Sweetalert from 'sweetalert2';

interface procedureCalculaItensXmlProps {
  fornecedor: FornecedorProps;
  nf_xml_item: ItensProps;
}

export default async function procedureCalculaItensXml({
  fornecedor,
  nf_xml_item,
}: procedureCalculaItensXmlProps) {
  let cod_tributacao = 0;
  let tipo_tributacao = 0;
  let per_icms = 0;
  let val_reducao_base_calculo = 0;
  let cod_sit_tributacao = 0;
  let index = -1;
  const tributacoes: any[] = await getTributacoes();
  // eslint-disable-next-line prefer-const
  index = tributacoes.findIndex(
    (i) => i.cod_tributacao === fornecedor.cod_trib_simples,
  );
  if (
    fornecedor.flg_optante_simples === true &&
    [0, 2].includes(nf_xml_item.tipo_trib_ent) &&
    fornecedor.cod_trib_simples !== 0 &&
    index !== -1
  ) {
    cod_tributacao = fornecedor.cod_trib_simples;
    tipo_tributacao = tributacoes[index].tipo_tributacao;
    per_icms = tributacoes[index].val_icms;
    val_reducao_base_calculo = tributacoes[index].val_reducao;
    cod_sit_tributacao = tributacoes[index].cod_situacao_tributaria;
  } else {
    if (
      nf_xml_item.cfop === 556 &&
      [0, 2].includes(nf_xml_item.tipo_trib_ent)
    ) {
      index = tributacoes.findIndex(
        (i) =>
          i.tipo_tributacao === 6 &&
          i.val_icms === 0 &&
          i.cod_situacao_tributaria === '090',
      );
      if (index !== -1) {
        cod_tributacao = fornecedor.cod_trib_simples;
        tipo_tributacao = tributacoes[index].tipo_tributacao;
        per_icms = tributacoes[index].val_icms;
        val_reducao_base_calculo = tributacoes[index].val_reducao;
        cod_sit_tributacao = tributacoes[index].cod_situacao_tributaria;
      } else {
        Sweetalert.fire(
          `Produto: ${nf_xml_item.cod_produto} de Uso e Consumo mas não foi encontrada tributação do tipo Outros 'ICMS' e 'CST 090'.`,
        );
      }
    } else {
      cod_tributacao = nf_xml_item.cod_trib_entrada;
      tipo_tributacao = nf_xml_item.tipo_trib_ent;
      per_icms = nf_xml_item.val_icms_ent;
      val_reducao_base_calculo = nf_xml_item.val_reducao_ent;
      cod_sit_tributacao = Number(nf_xml_item.cod_sit_tributaria_ent);
    }
  }

  const tributacao = tributacoes.find(
    (item) => item.cod_tributacao === cod_tributacao,
  );
  return {
    cod_tributacao,
    tipo_tributacao,
    per_icms,
    val_reducao_base_calculo,
    cod_sit_tributacao,
    tributacao,
  };
}
